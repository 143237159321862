<template>
    <modal ref="modalImportarNuevosPrecios" titulo="Importar actualización de datos" no-aceptar :cargado-modal="loading" adicional="Cargar" @adicional="upFile">
        <div class="row mx-0 align-items-center py-3 px-4">
            <el-upload
            ref="upload"
            action="#"
            :on-change="changeFile"
            :on-remove="removeFile"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            accept=".xlsx"
            >
                <div class="bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow">
                    Click para subir archivo
                </div>
            </el-upload>
            <p v-if="!model.file" class="mx-0 f-14 text-general mt-2">Solo se aceptan archivos con extensión ( .xlsx )</p>
        </div>
        <div class="row mx-0 align-items-center py-3 px-4">
            <div class="col-auto px-0">
                <el-radio v-model="model.type" :label="1" class="radio-green">
                    Trabajar con SKU
                </el-radio>
            </div>
            <div class="col-auto">
                <el-radio v-model="model.type" :label="2" class="radio-green">
                    Trabajar con BARCODE
                </el-radio>
            </div>
        </div>
    </modal>
</template>
<script>
import Service from "~/services/productos/productosAdmin";

export default {
    data(){
        return {
            url: window.location.pathname,
            loading: false,
            model: {
                idCedis: Number(this.$route.params.id_cedis),
                file: null,
                fileName: null,
                type: 1,
            },
        }
    },

    methods: {
        toggle(){
            this.clear();
            this.$refs.modalImportarNuevosPrecios.toggle();
        },

        async upFile(){
            try {
                if(!this.model.idCedis) return this.notificacion('Espera', 'No puedes realizar esta acción por qué no estás dentro de un cedis', 'warning');

                if(_.isNull(this.model.file))return;

                this.loading = true;
                
                const model = this.crear_formData(this.model);
                const {data} = await Service.putImportProductsUpdate(model);
                
                const imported = {
                    data: data.data,
                    existen: data.existen,
                    noExisten: data.no_existen,
                    type: this.model.type,
                    fileName: this.model.fileName
                };

                this.$refs.modalImportarNuevosPrecios.toggle();

                if(this.url == '/admin/centro-distribuccion/ver/actualizar-datos'){
                    const params = {id_cedis: this.model.idCedis, imported: imported};
                    return this.$emit('updateFile', params)
                }
                
                this.$router.push({name:'admin.cedis.actualizar-datos', params:{id_cedis: this.model.idCedis, imported: imported}});
            } catch(e){
                this.error_catch(e);
            } finally{
                this.loading = false;
            }
        },

        changeFile(file){
            let ext = file.name.split('.').pop();

            if(ext != 'xlsx'){
                this.notificacion('Alerta', 'La extensión del archivo no coincide con la permitida (.xlsx)', 'warning');
                this.removeFile();
                return;
            }
            console.log(file.raw);
            this.model.file = file.raw;
            this.model.fileName = file.name;
        },

        removeFile(){
            this.model.file = null;
            this.model.fileName = null;
            this.$refs.upload.clearFiles();
        },

        clear(){
            this.loading = false;
            this.model.type = 1;
            this.removeFile();
        },
    }
}
</script>
